import React, {FC, FormEvent, useState} from 'react';

import classes from './PrintOrderEdit.module.scss';
import Button from "../Button/Button";
import {Address} from "../../utils/Address";
import {Order} from "../../utils/Order";
import {OrderItem} from "../../utils/OrderItem";
import {useAuth} from "../../utils/Auth";
import {useNavigate} from "react-router-dom";
import PrintOrderItemRow from "../PrintOrderItemRow/PrintOrderItemRow";

import {
  updateOrderItemFile,  
  updateOrderItemQuantity
} from "../../utils/api";

type PrintOrderEditProps = {
  order: Order;
  onSaveOrder?: (orderId: number, address: Address) => void;
  onCancelEdit?: () => void;    
}
const PrintOrderEdit: FC<PrintOrderEditProps> = ({order, onSaveOrder, onCancelEdit}) => {
    const [firstName, setFirstName] = useState(order && order.shippingAddress ? order.shippingAddress.firstName : '');    
    const [lastName, setLastName] = useState(order && order.shippingAddress ? order.shippingAddress.lastName : '');    
    const [address, setAddress] = useState(order && order.shippingAddress ? order.shippingAddress.address : '');    
    const [city, setCity] = useState(order && order.shippingAddress ? order.shippingAddress.city : '');
    const [zip, setZip] = useState(order && order.shippingAddress ? order.shippingAddress.zip : '');
    const [email, setEmail] = useState(order && order.shippingAddress ? order.shippingAddress.email : '');    
    const [mobile, setMobile] = useState(order && order.shippingAddress ? order.shippingAddress.mobile : '');    
    const {authToken} = useAuth();
    const navigate = useNavigate();

    const handleUpdateFile = async (orderItemId: number, file?: File) => {
        if (!authToken) {
        return;
        }

        const ret = await updateOrderItemFile(authToken, orderItemId,  file);
        if (ret === null) {
        navigate("/", {state: { logout: true}});
        }
    }

    const handleUpdateQuantity = async (orderItemId: number, quantity: number) => {
        if (!authToken) {
            return;
        }

        if (quantity == undefined || quantity < 0) {
            return;        
        }

        const ret = await updateOrderItemQuantity(authToken, orderItemId, quantity);
        if (ret === null) {
            navigate("/", {state: { logout: true}});
        }
    }

    const handleSave = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();

        if (order && firstName) {
            const shippingAddress = {
                firstName,
                lastName,
                email,
                mobile,
                address,
                city,
                zip,                
                country: {
                    id: 0,
                    name: '',
                    isoCode: ''
                }
            }
            
            onSaveOrder && onSaveOrder(order.id, shippingAddress);
        }

        return false;
    }
  return (
    <div className={classes.printOrderEdited}>
      <div className={classes.header}>
        <div className={classes.headerItem}>{order.orderNumber}</div>
        <div>{order.orderDate}</div>        
      </div>
      {
        order.shippingAddress && (
          <form onSubmit={handleSave}>
              <input className={classes.shippingAddressInput} value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
              <input className={classes.shippingAddressInput} value={lastName} onChange={(e) => setLastName(e.target.value)}/>          
              <input className={classes.shippingAddressInput} value={address} onChange={(e) => setAddress(e.target.value)}/>
              <input className={classes.shippingAddressInput} value={zip} onChange={(e) => setZip(e.target.value)}/>
              <input className={classes.shippingAddressInput} value={city} onChange={(e) => setCity(e.target.value)}/>
              <div className={classes.shippingAddressInput}>{order.shippingAddress.country.name}</div>                            
              <input className={classes.shippingAddressInput} value={email} onChange={(e) => setEmail(e.target.value)}/>          
              <input className={classes.shippingAddressInput} value={mobile} onChange={(e) => setMobile(e.target.value)}/>
          </form>
        )
      }
      <hr/>
      <div>
        {
          order.items.map((oi: OrderItem) => (
                    <PrintOrderItemRow
                      key={oi.id}
                      orderItem={oi}
                      onUpdateFile={handleUpdateFile}
                      onUpdateQuantity={handleUpdateQuantity}
                    />              
          ))
        }
      </div>

      
        <Button
          className={classes.saveButton}
          label='Save order'
          onClick={handleSave}
        />
      
        <Button
          className={classes.cancelEditButton}
          label='Cancel edit order'
          onClick={onCancelEdit}
        />
      
    </div>
  );
}

export default PrintOrderEdit;
