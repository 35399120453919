import React, {FC, useEffect, useRef, useState} from 'react';

import {ReactComponent as UploadSvg} from 'images/upload.svg';

import classes from './PrintOrderItemRow.module.scss';

import {
  updateOrderItemFile,  
  updateOrderItemQuantity
} from "../../utils/api";

type PrintOrderItemRowProps = {
  orderItem: any;
  onUpdateFile: (orderItemId: number, file?: File) => void;
  onUpdateQuantity: (orderItemId: number, newQuantity: number) => void;
}
const PrintOrderItemRow: FC<PrintOrderItemRowProps> = ({orderItem, onUpdateFile, onUpdateQuantity}) => {
  const [quantity, setQuantity] = useState(orderItem?.quantity || 0);
  const uploadFileRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    uploadFileRef.current?.click();
  };

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (uploadFileRef.current?.files?.length) {
      const file = uploadFileRef.current?.files[0];
      onUpdateFile(orderItem.id, file);
    }
  }

  useEffect(() => {
    if(orderItem?.quantity) {
      setQuantity(orderItem.quantity);
    }
  }, [orderItem]);

  const handleUpdateQuantity = (value: number) => {
    setQuantity(value);
    onUpdateQuantity(orderItem.id, value);
  }

  return (
    <div className={classes.fileItem}>
      <div className={classes.mapSize}>{orderItem.mapSize}</div>
      <div>{orderItem.productName}</div>

      <div className={classes.quantityRow}>
        <input
          type="number"
          className={classes.quantity}
          min={0}
          value={quantity}
          onChange={(e) => handleUpdateQuantity(+e.target.value)}
        />
      </div>
      <div className={classes.imageCell}>
        {
          orderItem.fileName ? (
            <div className={classes.uploadButton} onClick={handleUploadClick}>
              <UploadSvg className={classes.img}/>
              <input
                type="file"
                ref={uploadFileRef}
                style={{display: "none"}}
                accept="application/pdf"
                onChange={handleOnFileChange}
              />
            </div>
          ) : (
            <div>&nbsp;</div>
          )
        }
      </div>
    </div>
  );
}

export default PrintOrderItemRow;
