import React from 'react';
import classes from './PrintAdminPage.module.scss';
import {updatePages} from "../../utils/api";
import {useAuth} from "../../utils/Auth";
import {useNavigate} from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import {HOME_PRODUCTS, ORDER, PRINT, PRINT_DESCRIPTION} from "../../utils/pages";
import {useContent} from "../../hooks/useContent";
import ReactQuill from "react-quill";

const PrintAdminPage = () => {
  const [content, setContent, fetchHomePageData] = useContent(PRINT);
  const {authToken} = useAuth();
  const navigate = useNavigate();

  const handleSave = async () => {
    if (!authToken) {
      return;
    }

    const savePages = Object.entries(content).map(value => ({
      page: PRINT,
      code: value[0],
      value: value[1]
    }));

    const updatePageRef = await updatePages(savePages, authToken);

    if (updatePageRef === null) {
      navigate("/", {state: { logout: true}});
    } else {
      await fetchHomePageData();
    }
  }

  const handleOnChange = (field: string, value: string) => {
    setContent( (content: any) => ({
      ...content,
      [field]: value
    }));
  }

  if (!content) {
    return null;
  }

  return (
    <div className={classes.printAdminPage}>
      <h2 className={classes.label}>Description</h2>
      <div className={classes.block}>
        <ReactQuill theme="snow" value={content[PRINT_DESCRIPTION] || ''} onChange={(value) => handleOnChange(PRINT_DESCRIPTION, value)} />
      </div>

      <div className={classes.buttonContainer}>
        <button className={classes.saveButton} onClick={handleSave}>Save</button>
      </div>
    </div>
  );
}

export default PrintAdminPage;
