export type MapSize = 'A4'| 'A3'| 'A2'| 'A1'| 'A0'| 'Annat';
export const MAP_SIZES = ['A4', 'A3', 'A2', 'A1', 'A0', 'Annat'] as const;

export type Product = {
  product: string;
  productName: string;
  fileRequiring: boolean;
  mapSize: MapSize;
  cost?: number;
  printCost?: number;
  A4?: number;
  A3?: number;
  A2?: number;
  A1?: number;
  A0?: number;
  Annat?: number;
}