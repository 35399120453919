import {Product} from "./Product";
import {AdminSettings} from "./AdminSettings";
import {Address} from "./Address";
import {SentEmail} from "./SentEmail";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://jaktkarta.se/api';
const REACT_APP_BACKEND_DOWNLOAD_URL = process.env.REACT_APP_BACKEND_DOWNLOAD_URL || 'https://jaktkarta.se';

export async function register(email: string): Promise<any> {
  try {
    const response = await fetch(`${BACKEND_URL}/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        email
      }),
    });
    const result = await response.json();
    if (result?.error) {
      return undefined;
    }

    return result;
  } catch (error) {
    console.error('error', error);
  }
}

export async function login(token: string): Promise<any> {
  try {
    const response = await fetch(`${BACKEND_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        token
      }),
    });

    const result = await response.json();
    if (result?.error) {
      return undefined;
    }

    return result;
  } catch (error) {
    console.error('error', error);
  }
}

export async function fetchPrices(): Promise<undefined | Array<Product>> {
  try {
    const response = await fetch(`${BACKEND_URL}/price`, {
      method: 'GET',
      headers: {
        'Type': 'application/json',
      },
    });

    const result = await response.json();

    if (!result || result?.error) {
      return undefined;
    }

    return result as Array<Product>;
  } catch (error) {
    console.error('error', error)
  }
  return undefined;
}

export async function getOrder(token: string): Promise<undefined | any> {
  try {
    const response = await fetch(`${BACKEND_URL}/order`, {
      method: 'GET',
      headers: {
        'Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });

    if (response.status === 401) {
      return null;
    }

    const result = await response.json();

    if (!result || result?.error) {
      return undefined;
    }

    return result;
  } catch (error) {
    console.error('error', error);
  }

  return undefined;
}

export async function deleteOrderItem(orderItemId: number, token: string): Promise<undefined | any> {
  try {
    const response = await fetch(`${BACKEND_URL}/order/item/${orderItemId}`, {
      method: 'DELETE',
      headers: {
        'Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });

    if (401 === response.status) {
      return null;
    }

    const result = await response.json();

    if (!result || result?.error) {
      return undefined;
    }

    return result;
  } catch (error) {
    console.error('error', error);
  }

  return undefined;
}


export async function addOrderItem(token: string, product: string, mapSize: string, quantity: number, file?: File) {
  try {
    const formData = new FormData();

    const orderItem: any =  {
      product,
      quantity,
      mapSize
    };

    const orderItemJson = JSON.stringify(orderItem);

    formData.append('orderItem', new Blob([orderItemJson], {type: 'application/json'}),);
    if (file) {
      formData.append('file', file, file.name);
    }

    const response = await fetch(`${BACKEND_URL}/order/item`, {
      method: 'POST',
      headers: {
        'Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: formData,
    });

    if (401 === response.status) {
      return null;
    }

    const result = await response.json();

    if (result?.error) {
      return undefined;
    }

    return result;

  } catch(error) {
    console.error('error', error)
  }
}

export async function updateOrderItemFile(token: string, orderItemId: number, file?: File) {
  try {
    const formData = new FormData();

    const orderItem: any =  {
      id: orderItemId, 
      quantity: 0
    };

    const orderItemJson = JSON.stringify(orderItem);

    formData.append('orderItem', new Blob([orderItemJson], {type: 'application/json'}),);
    
    if (file) {
        formData.append('file', file, file.name);        
    }        

    const response = await fetch(`${BACKEND_URL}/orders/item-update-file/${orderItemId}`, {
      method: 'PUT',
      headers: {
        'Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: formData,
    });

    if (401 === response.status) {
      return null;
    }

  } catch(error) {
    console.error('error', error)
  }
}

export async function updateOrderItemQuantity(token: string, orderItemId: number, quantity: number) {

  const updateQuantityBody = {
    quantity
  }

  try {
    const response = await fetch(`${BACKEND_URL}/order/item/${orderItemId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(updateQuantityBody),
    });

    if (401 === response.status) {
      return null;
    }
  } catch (error) {
    console.error('error', error);
  }

  return undefined;
}

export async function getAdminSettings(token: string): Promise<undefined | null | AdminSettings> {
  try {
    const response = await fetch(`${BACKEND_URL}/admin/settings`, {
      method: 'GET',
      headers: {
        'Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });

    if (401 === response.status) {
      return null;
    }

    const result = await response.json();

    if (!result || result?.error) {
      return undefined;
    }

    return result as AdminSettings;
  } catch (error) {
    console.error('error', error);
  }

  return undefined;
}

export async function updateAdminSettings(settings: AdminSettings, token: string): Promise<undefined | null> {
  try {
    const response = await fetch(`${BACKEND_URL}/admin/settings`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(settings),
    });

    if (401 === response.status) {
      return null;
    }
  } catch (error) {
    console.error('error', error);
  }

  return undefined;
}

export async function updatePage(page: string, code: string, value: string, token: string): Promise<undefined | null> {
  const body = [
    {
      page,
      code,
      value
    }
  ];

  try {
    const response = await fetch(`${BACKEND_URL}/page`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body),
    });

    if (401 === response.status) {
      return null;
    }
  } catch (error) {
    console.error('error', error);
  }

  return undefined;
}

export async function updatePages(pages: Array<any>, token: string): Promise<undefined | null> {
  try {
    const response = await fetch(`${BACKEND_URL}/page`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(pages),
    });

    if (401 === response.status) {
      return null;
    }
  } catch (error) {
    console.error('error', error);
  }

  return undefined;
}

export async function getAllPageContent(token: string): Promise<undefined | null | Array<any>> {
  try {
    const response = await fetch(`${BACKEND_URL}/page`, {
      method: 'GET',
      headers: {
        'Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });

    if (401 === response.status) {
      return null;
    }

    const result = await response.json();

    if (!result || result?.error) {
      return undefined;
    }

    return result as Array<any>;
  } catch (error) {
    console.error('error', error);
  }

  return undefined;
}
export async function getPageContent(page: string): Promise<undefined | null | Array<any>> {
  try {
    const response = await fetch(`${BACKEND_URL}/page?page=${page}`);

    if (401 === response.status) {
      return null;
    }

    const result = await response.json();

    if (!result || result?.error) {
      return undefined;
    }

    return result as Array<any>;
  } catch (error) {
    console.error('error', error);
  }

  return undefined;
}

export async function buyOrder(token: string): Promise<undefined | any> {
  try {
    const response = await fetch(`${BACKEND_URL}/order/buy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (401 === response.status) {
      return null;
    }

    const data = await response.text();

    return data;
  } catch (error) {
    console.error('error', error);
  }

  return undefined;
}

export async function orderConfirmation(klarnaOrderId: string, token: string): Promise<undefined | any> {
  try {
    const response = await fetch(`${BACKEND_URL}/order/confirmation/${klarnaOrderId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (401 === response.status) {
      return null;
    }

    return await response.json();
  } catch (error) {
    console.error('error', error);
  }

  return undefined;
}

export async function getOrdersByStatus(status: string, token: string): Promise<undefined | any> {
  try {
    const response = await fetch(`${BACKEND_URL}/orders/${status}`, {
      method: 'GET',
      headers: {
        'Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });

    if (401 === response.status) {
      return null;
    }

    const result = await response.json();

    if (!result || result?.error) {
      return [];
    }

    return result;
  } catch (error) {
    console.error('error', error);
  }

  return [];
}

export async function moveOrders(orderIds: Array<number>, token: string): Promise<undefined | any> {
  try {
    const updateOrder = {
      orderIds,
      description: ''
    }

    const response = await fetch(`${BACKEND_URL}/orders`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(updateOrder),
    });

    if (401 === response.status) {
      return null;
    }

  } catch (error) {
    console.error('error', error);
  }
}

export async function downloadFile(downloadPath: string, fileName: string, token: string) {
  const res = await fetch(`${REACT_APP_BACKEND_DOWNLOAD_URL}${downloadPath}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  if (res.status === 401) {
    return null;
  }

  const blob = await res.blob();

  const href = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function downloadAndOpenFile(downloadPath: string, fileName: string, token: string) {
  const res = await fetch(`${REACT_APP_BACKEND_DOWNLOAD_URL}${downloadPath}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  if (res.status === 401) {
    return null;
  }

  const blob = await res.blob();

  //Build a URL from the file
  const fileURL = URL.createObjectURL(blob);
  //Open the URL on new Window
  const pdfWindow = window.open();
  if (pdfWindow) {
    pdfWindow.location.href = fileURL;
  }
}

export async function addOrderComment(description: string, orderId: number, token: string): Promise<undefined | any> {
  try {
    const orderComment = {
      description
    }

    const response = await fetch(`${BACKEND_URL}/orders/comments/${orderId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(orderComment)
    });

    if (401 === response.status) {
      return null;
    }

  } catch (error) {
    console.error('error', error);
  }
}

export async function deleteOrderComment(commentId: number, token: string): Promise<undefined | any> {
  try {
    const response = await fetch(`${BACKEND_URL}/orders/comments/${commentId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });

    if (401 === response.status) {
      return null;
    }

  } catch (error) {
    console.error('error', error);
  }
}

export async function editOrder(orderId: number, token: string): Promise<undefined | any> {
  try {

    const response = await fetch(`${BACKEND_URL}/orders/edit/${orderId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (401 === response.status || 403 === response.status ) {
      return null;
    }

  } catch (error) {
    console.error('error', error);
  }
}

export async function saveOrder(orderId: number, token: string, address: Address): Promise<undefined | any> {
    try {

        const response = await fetch(`${BACKEND_URL}/orders/save/${orderId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(address)            
        });

        if (401 === response.status || 403 === response.status ) {
            return null;
        }

    } catch (error) {
        console.error('error', error);
    }
}

export async function cancelEditOrder(orderId: number, token: string): Promise<undefined | any> {
  try {

    const response = await fetch(`${BACKEND_URL}/orders/cancel-edit/${orderId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (401 === response.status || 403 === response.status ) {
      return null;
    }

  } catch (error) {
    console.error('error', error);
  }
}

export async function findSentEmail(email: string,  token: string): Promise<null | Array<SentEmail>> {
  try {
    const response = await fetch(`${BACKEND_URL}/admin/find-sent-emails?email=${email}`, {
      method: 'GET',
      headers: {
        'Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });

    if (401 === response.status) {
      return null;
    }

    const result = await response.json();

    if (!result || result?.error) {
      return [];
    }

    return result as Array<SentEmail>;
  } catch (error) {
    console.error('error', error);
  }

  return [];
}
