import React from 'react';
import { ReactComponent as LogoWhiteSvg } from 'images/logo-white.svg';
import KLarnaWhiteLogo from 'images/klarna-white-logo.svg';
import VisaWhiteLogo from 'images/visa-white-logo.png';
import MastercardWhiteLogo from 'images/mastercard-white-logo.png';
import classes from './Footer.module.scss';
import {Link} from "react-router-dom";
import cn from "classnames";


const Footer= () => {
    return (
      <div className={classes.footer}>
        <div className={classes.wrapper}>
          <div className={classes.container}>
            <div className={classes.logoColumn}>
              <LogoWhiteSvg className={classes.logo}/>
              <div className={cn(classes.copy, classes.copyHideMobile)}>© Jaktkarta.se 2023</div>
            </div>
            <div className={classes.menu}>
              <div className={classes.links}>
                <a target="_blank" className={classes.link} href="/contact">Kontakt</a>
                <a target="_blank" className={classes.link} href="/terms-of-purchase">Köpvillkor</a>
                <a target="_blank" className={classes.link} href="/about">Om Jaktkarta.se</a>
              </div>
              <div className={classes.links}>
                <a target="_blank"className={classes.link} href="/privacy-policy">Integritetspolicy</a>
                <a target="_blank" className={classes.link} href="/cookie-policy">Cookie Policy</a>
              </div>
            </div>
            <div className={classes.copy}>© Jaktkarta.se 2023</div>
          </div>
        </div>

        <div className={classes.paymentLogos}>
          <img className={classes.paymentLogo} src={KLarnaWhiteLogo} />
          <img className={classes.paymentLogo} src={VisaWhiteLogo} />
          <img className={classes.paymentLogo} src={MastercardWhiteLogo} />
        </div>
      </div>
    );
}

export default Footer;
