import React, {FC, useEffect, useState} from 'react';

import classes from './PriceList.module.scss';
import {fetchPrices} from "../../utils/api";
import {Product, MAP_SIZES} from "../../utils/Product";
import {priceFormat} from "../../utils/priceFormat";
import {HOME_PRODUCTS, HOME_PRODUCTS_NOTE} from "../../utils/pages";

type PriceListProps = {
  content?: any;
};

const PriceList: FC<PriceListProps> = ({content}) => {
  const [prices, setPrices] = useState<Array<any>>([]);
  useEffect(() => {
    const updatePrices = async () => {
      const serverPrices = await fetchPrices();
      const filteredPrices = (serverPrices || []).filter(p => !!p);

      const newProducts: Array<Product> = [];

      filteredPrices.forEach(fp => {
        let newProduct: any = newProducts.find(np => fp.product === np.product);
        const sizeColumn = MAP_SIZES.includes(fp.mapSize) ? fp.mapSize : 'Annat';
        if (!newProduct) {
          newProduct = {
            product: fp.product,
            productName: fp.productName,
            [sizeColumn]: fp.cost,
          };

          newProducts.push(newProduct);
        } else {
          newProduct[sizeColumn] = fp.cost;
        }
      });

      setPrices(newProducts);
    }

    updatePrices();
  }, []);

  return (
    <div className={classes.priceList}>
      <div className={classes.title}>Pris i kronor inklusive moms:</div>
      <table className={classes.table}>
        <thead>
        <tr>
          <th>Storlek*</th>
          <th>A4</th>
          <th>A3</th>
          <th>A2</th>
          <th>A1</th>
          <th>A0</th>
          <th>Annat</th>
        </tr>
        </thead>
        <tbody>
        {
          prices.map(p => (
              <tr key={p.product}>
                <td>{p.productName}</td>
                {
                  MAP_SIZES.map(c => (
                      <td>{p[c] ? priceFormat(p[c], 0) : ''}</td>
                  ))
                }
              </tr>
          ))
        }

        </tbody>
      </table>
      {
        (content && content[HOME_PRODUCTS_NOTE]) ? (
          <div className={classes.description} dangerouslySetInnerHTML={{__html: (content && content[HOME_PRODUCTS_NOTE]) || ''}}></div>
        ): (
          <div className={classes.description}>
            <div>Önskas kartor större än A0? Räkna med dubbla priset för dubbla storleken. Offertförfrågan på info@jaktkarta.se.</div>
            <div>*) A4 = 21 x 30 cm, A3 = 30 x 42 cm, A2 = 42 x 59 cm, A1 = 59 x 84 cm, A0 = 84 x 119 cm</div>
          </div>
        )
      }
    </div>
  );
}

export default PriceList;
