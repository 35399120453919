import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Header from "./components/Header/Header";
import classes from './App.module.scss';
import {AuthContext} from 'utils/Auth';
import Footer from "./components/Footer/Footer";

const TOKEN_KEY = 'token';
const ANONYMOUS_PATH = ['login', 'contact', 'about', 'cookie-policy', 'privacy-policy', 'terms-of-purchase']
const App = () => {
  const { pathname } = useLocation();
  const [authToken, setAuthToken] = useState<string | null>(
    localStorage.getItem(TOKEN_KEY) || ''
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const setToken = (token: string) => {
    setAuthToken(token);
    localStorage.setItem(TOKEN_KEY, token);
  };

  useEffect(() => {
    if (location.state?.logout === true) {
      setAuthToken('');
      localStorage.removeItem(TOKEN_KEY);
    }
  }, [authToken, location]);

  useEffect(() => {
    if (authToken === '' && location.pathname !== '/' && ANONYMOUS_PATH.findIndex(path => location.pathname.startsWith(`/${path}`)) < 0 ) {
      navigate('/');
    }
  }, [])

  return (
    <AuthContext.Provider value={{authToken, setAuthToken: setToken}}>
      <div className={classes.app}>
        <Header/>
        <Outlet/>
        <Footer/>
      </div>
    </AuthContext.Provider>
  )
}

export default App;
