import React, {useEffect, useRef, useState} from 'react';
import classes from './HomePage.module.scss';
import Information from "../../components/Information/Information";
import Slideshow from "../../components/Slideshow/Slideshow";
import AboutProduct from "../../components/AboutProduct/AboutProduct";
import {useNavigate, useParams} from "react-router-dom";
import {getPageContent, login} from "../../utils/api";
import {useAuth} from "../../utils/Auth";
import ProductsList from "../../components/ProductsList/ProductsList";
import LoginModal from "../../components/LoginModal/LoginModal";
import InformationButtons from "../../components/InformationButtons/InformationButtons";
import {HOME, HOME_TITLE} from "../../utils/pages";
import {useContent} from "../../hooks/useContent";

const HomePage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { token } = useParams<"token">();
  const navigate = useNavigate();
  const [content] = useContent(HOME);

  const { authToken, setAuthToken } = useAuth();

  const refAboutProduct = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (token) {
      login(token)
        .then((user) => {
          setAuthToken(user.token);

          navigate('/order');
        });
    }
  }, [token])

  const handleLogin = () => { 
    if (authToken) {
      navigate('/order');
    } else {
      setModalIsOpen(true);
    }
  }

  const handleOnScroll = () => {
    refAboutProduct.current?.scrollIntoView({ behavior: 'smooth', block: "center" })
  }

  const handleLoginOnClose = () => {
    setModalIsOpen(false);
  }

  return (
    <div  className={classes.home}>
      <h1 className={classes.title}>{content[HOME_TITLE] || 'Vattentåliga kartor för jaktlaget'}</h1>
      <div className={classes.main}>
        <div className={classes.information}>
          <Information content={content}/>
        </div>
        <div className={classes.slideshow}>
          <Slideshow />
        </div>
        <div className={classes.buttons}>
          <InformationButtons onLogin={handleLogin} onScroll={handleOnScroll}/>
        </div>

      </div>
      <ProductsList onLogin={handleLogin} onScroll={handleOnScroll} content={content}/>
      <AboutProduct ref={refAboutProduct} onLogin={handleLogin} content={content}/>
      <LoginModal isOpen={modalIsOpen} onClose={handleLoginOnClose}/>
    </div>
  );
}

export default HomePage;
