import React, {useEffect, useState} from 'react';
import classes from './HomeAdminPage.module.scss';
import {getPageContent, updatePages} from "../../utils/api";
import {useAuth} from "../../utils/Auth";
import {useNavigate} from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {HOME, HOME_INFORMATION, HOME_PRODUCTS, HOME_PRODUCTS_NOTE, HOME_TITLE} from "../../utils/pages";
import {useContent} from "../../hooks/useContent";

const HomeAdminPage = () => {
  const [content, setContent, fetchContent] = useContent(HOME);
  const {authToken} = useAuth();
  const navigate = useNavigate();

  const handleSave = async () => {
    if (!authToken) {
      return;
    }

    const savePages = Object.entries(content).map(value => ({
      page: HOME,
      code: value[0],
      value: value[1]
    }));

    const updatePageRef = await updatePages(savePages, authToken);

    if (updatePageRef === null) {
      navigate("/", {state: { logout: true}});
    } else {
      await fetchContent();
    }
  }

  const handleOnChange = (field: string, value: string) => {
    setContent( (content: any)=> ({
      ...content,
      [field]: value
    }));
  }

  if (!content) {
    return null;
  }

  return (
    <div className={classes.homeAdminPage}>
      <h2 className={classes.blockTitle}>Title</h2>
      <div className={classes.block}>
        <input className={classes.input} value={content[HOME_TITLE ] || ''} onChange={(e) => handleOnChange(HOME_TITLE, e.target.value)}/>
      </div>

      <h2 className={classes.blockTitle}>Information</h2>
      <div className={classes.block}>
        <ReactQuill theme="snow" value={content[HOME_INFORMATION] || ''} onChange={(value) => handleOnChange(HOME_INFORMATION, value)} />
      </div>

      {
        [1, 2, 3, 4, 5, 6, 7].map(i => (
          <>
            <h2 className={classes.blockTitle}>Product {i}</h2>
            <div className={classes.block}>
              <input className={classes.input} value={content[`HOME_PRODUCT${i}_TITLE`] || ''} onChange={(e) => handleOnChange(`HOME_PRODUCT${i}_TITLE`, e.target.value)}/>
              <textarea className={classes.textarea} value={content[`HOME_PRODUCT${i}_DESCRIPTION` ] || ''} onChange={(e) => handleOnChange(`HOME_PRODUCT${i}_DESCRIPTION`, e.target.value)}/>
            </div>
          </>
        ))
      }
      <h2 className={classes.blockTitle}>Products</h2>
      <div className={classes.block}>
        <ReactQuill theme="snow" value={content[HOME_PRODUCTS] || ''} onChange={(value) => handleOnChange(HOME_PRODUCTS, value)} />
      </div>

      <h2 className={classes.blockTitle}>Products note</h2>
      <div className={classes.block}>
        <ReactQuill theme="snow" value={content[HOME_PRODUCTS_NOTE] || ''} onChange={(value) => handleOnChange(HOME_PRODUCTS_NOTE, value)} />
      </div>


      <div className={classes.buttonContainer}>
        <button className={classes.saveButton} onClick={handleSave}>Save</button>
      </div>
    </div>
  );
}

export default HomeAdminPage;
