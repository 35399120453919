import React, {FC, useEffect, useState} from 'react';

import classes from './OrderTable.module.scss';
import gc from 'global.module.scss';
import OrderItemRow from "../OrderItemRow/OrderItemRow";
import {
  addOrderItem,
  deleteOrderItem,
  downloadAndOpenFile,
  downloadFile,
  fetchPrices,
  updateOrderItemQuantity
} from "../../utils/api";
import {useAuth} from "../../utils/Auth";
import {Order} from "../../utils/Order";
import {useNavigate} from "react-router-dom";

type OrderTableProps = {
  order?: Order;
  disabled: boolean;
  onOrderUpdated: () => void;
}
const OrderTable: FC<OrderTableProps> = ({order, disabled, onOrderUpdated}) => {
  const [orderItems, setOrderItems] = useState<any[]>([]);
  const [products, setProducts] = useState<Array<string>>([]);
  const {authToken} = useAuth();
  const navigate = useNavigate();

  const fetchData = async () => {
    const serverPrices = await fetchPrices();
    const serverProducts = (serverPrices ?? []).reduce((c: Array<any>, p) => {if (p && p.product !== "SHIPPING" && !c.includes(p.product)) c.push(p.product); return c;}, []);

    setProducts(serverProducts);

    const filteredPrices = (serverPrices || [])?.filter((p: any) => !!p && p.product !== "SHIPPING");

    const newOrderItems: any[] = [];
    filteredPrices.forEach(p => {
      const oi = (order?.items || []).filter((o: any) => o.product === p.product && o.mapSize === p.mapSize);

      if (oi.length) {
        newOrderItems.push(...oi.map((o: any) => ({
          ...p,
          ...o
        })));

        if (!p.fileRequiring) {
          return;
        }
      }

      newOrderItems.push({
        ...p,
        price: p.cost,
      });
    });

    setOrderItems(newOrderItems);
  }

  useEffect(() => {
    fetchData();
  }, [order]);

  const handleAddOrderItem = async (product: string, mapSize: string, quantity: number, file?: File) => {
    if (disabled || !authToken) {
      return;
    }

    const ret = await addOrderItem(authToken, product, mapSize, quantity, file);
    if (ret === null) {
      navigate("/", {state: { logout: true}});
    } else {
      onOrderUpdated && onOrderUpdated();
    }
  }

  const handleUpdateQuantity = async (orderItemId: number, quantity: number) => {
    if (disabled || !authToken) {
      return;
    }

    const ret = await updateOrderItemQuantity(authToken, orderItemId, quantity);
    if (ret === null) {
      navigate("/", {state: { logout: true}});
    } else {
      onOrderUpdated && onOrderUpdated();
    }
  }

  const handleDeleteOrderItem = async (orderItemId: number) => {
    if (disabled || !authToken) {
      return;
    }

    const deleteRet = await deleteOrderItem(orderItemId, authToken);
    if (deleteRet === null) {
      navigate("/", {state: { logout: true}});
    } else {
      await fetchData();

      onOrderUpdated && onOrderUpdated();
    }
  }

  const handleDownload = async (downloadPath: string, fileName: string) => {
    if (!authToken) {
      return;
    }

    await downloadAndOpenFile(downloadPath, fileName, authToken);
  }

  return (
    <div className={classes.orderTable}>
      <table className={classes.table}>
        <thead>
        <tr className={classes.tableHeader}>
          <th className={classes.productName}>Storlek</th>
          <th className={gc.textCenter}>Ladda upp fil</th>
          <th className={gc.textLeft}>Filnamn</th>
          <th className={gc.textCenter}>Ta bort</th>
          <th className={gc.textLeft}>Styckpris</th>
          <th className={gc.textLeft}>Antal</th>
          <th className={gc.textLeft}>Pris</th>
        </tr>
        </thead>
        <tbody>
        {
          products?.map((product) => {
            const productOrderItems = orderItems.filter(oi => oi.product === product);

            if (!productOrderItems.length) {
              return false;
            }

            return (
              <React.Fragment key={productOrderItems[0].productName}>
                <tr >
                  <td className={classes.productHeader} colSpan={6}>{productOrderItems[0].productName}</td>
                </tr>
                {
                  productOrderItems.map((oi: any) => (
                    <OrderItemRow
                      key={`${oi.id ?? 0}_${oi.product}_${oi.mapSize ?? ''}`}
                      orderItem={oi}
                      disabled={disabled}
                      onAddOrderItem={handleAddOrderItem}
                      onUpdateQuantity={handleUpdateQuantity}
                      onDeleteOrderItem={handleDeleteOrderItem}
                      onDownload={handleDownload}
                    />
                  ))
                }
              </React.Fragment>
            )
          })
        }

        </tbody>
      </table>
    </div>
  );
}

export default OrderTable;
