import React from 'react';
import StaticContent from "components/StaticContent/StaticContent";

const ContactPage = () => {
  return (
    <StaticContent
      title="Kontakt"
      primaryText={
        <>
          Frågor eller problem? Du når oss på <a href="mailto:info@jaktkarta.se">info@jaktkarta.se</a>. Vi svarar inom 24 timmar.
        </>
      }
    />
  );
}

export default ContactPage;