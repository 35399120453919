import React, {forwardRef} from 'react';

import classes from './AboutProduct.module.scss';
import PriceList from "../PriceList/PriceList";
import {ReactComponent as ArrowSvg} from 'images/arrow.svg';
import Button from "../Button/Button";
import {HOME_PRODUCTS} from "../../utils/pages";

type AboutProductProps = {
  content?: any;
  onLogin: () => void;
};

const AboutProduct = forwardRef(
  ({content, onLogin}: AboutProductProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div ref={ref} className={classes.aboutProduct}>
        <div className={classes.wrapper}>
          {
            (content && content[HOME_PRODUCTS]) ? (
              <div className={classes.top}
                   dangerouslySetInnerHTML={{__html: (content && content[HOME_PRODUCTS]) || ''}}></div>
            ) : (
              <div className={classes.top}>
                <h2 className={classes.title}>Hur du skaffar en jaktkarta</h2>
                <div>
                  För att beställa behöver du ha en färdig PDF-fil med din jaktkarta (eller annan karta / annat motiv).
                  Du kan skapa denna PDF på följande sätt:
                </div>
                <div className={classes.subtitle}>
                  WeHunt
                </div>
                <div>
                  Se instruktion {" "}
                  <a className={classes.link} target="_blank"
                     href="https://support.wehuntapp.com/sv-SE/support/solutions/articles/151000036780-hur-skriver-jag-ut-min- jaktmark-i-appen-">här</a>.
                </div>
                <div className={classes.subtitle}>
                  Tracker
                </div>
                <div>
                  Öppna Map Planner på en dator, högerklicka på kartan och skriv ut PDF.
                </div>
                <div className={classes.subtitle}>
                  Easyhunt
                </div>
                <div>
                  Gå till hemsidan &gt; karta på en dator. Välj storlek, anpassa kartan och ladda ner.
                </div>
                <div className={classes.subtitle}>
                  Program på PC och Mac
                </div>
                <div>
                  Spara eller skriv ut kartan som PDF.
                </div>
                <div>
                  Med WeHunt och Easyhunt väljer du storlek, t.ex. A3, på PDFen. Men det går att beställa med vilken
                  PDF-fil som helst, exempelvis en A0-karta baserad på en A3-fil. När du har PDFen klar klickar du på
                  BESTÄLL NU nedan. För frågor, maila <a className={classes.link}
                                                         href="mailto:info@jaktkarta.se">info@jaktkarta.se</a>.
                </div>
              </div>
            )
          }

          <PriceList content={content}/>
          <Button
            className={classes.loginButton}
            label="Beställ nu"
            onClick={onLogin}
            image={<ArrowSvg/>}
          />
        </div>
      </div>
    );
  }
)

export default AboutProduct;
