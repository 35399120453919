import React from 'react';
import {ReactComponent as LogoSvg} from 'images/logo.svg';
import {Link, useNavigate} from "react-router-dom";

import classes from './Header.module.scss';
import {useAuth} from "../../utils/Auth";

const Header = () => {
  const navigate = useNavigate();
  const {authToken} = useAuth();

  const handleLogout = () => {
    navigate("/", {state: { logout: true}});
  }

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div className={classes.header}>
      <div className={classes.container}>
        <Link to="/" onClick={handleLogoClick}>
          <LogoSvg className={classes.logo}/>
        </Link>
        <a target="_blank" className={classes.contact} href="/contact">Kontakt</a>
        {authToken && <div className={classes.logout} onClick={handleLogout}>Logga ut</div>}
      </div>
    </div>
  );
}

export default Header;
