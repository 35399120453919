import React from 'react';
import StaticContent from "components/StaticContent/StaticContent";

const AboutPage = () => {
  return (
    <StaticContent
      title="Om Jaktkarta.se"
      primaryText={
        <>
          <p><a href="https://jaktkarta.se">Jaktkarta.se</a> lanserades sommaren 2023 med syftet att ge jaktlag möjligheten att på ett enkelt sätt skaffa stora kartor till jaktgenomgångarna. <a href="https://jaktkarta.se">Jaktkarta.se</a> är en svensk tjänst och kartorna trycks i Sverige. Vi marknadsför oss bara i Sverige men levererar till hela EU och Norge.</p>
          <p>Vi har ingen telefonsupport, men vi svarar inom 24 timmar om du mailar oss på <a href="mailto:info@jaktkarta.se">info@jaktkarta.se</a>. Om du inte får något svar så kolla i din skräppostmapp. I nödfall kan du även kontakta oss på vår <a href="https://www.facebook.com/profile.php?id=100094076552930" target="_blank">Facebooksida</a> men där svarar vi inte lika ofta.</p>
        </>
      }
      paragraphs={
        []
      }
    />
  );
}

export default AboutPage;