import React, {FC} from 'react';
import classes from './ProductDescription.module.scss';

type ProductDescriptionProps = {
  title: string;
  description: string;
  imageUrl: string;
};

const ProductDescription: FC<ProductDescriptionProps> = ({title, description, imageUrl}) => {
    return (
      <div className={classes.productDescription}>
          <div>
              <h3 className={classes.title}>
                  {title}
              </h3>
              <div className={classes.description}>
                  {description}
              </div>
          </div>
          <div>
              <img className={classes.image} src={imageUrl} />
          </div>
      </div>
    );
}

export default ProductDescription;
