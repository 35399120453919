import React, {FC} from 'react';

import classes from './ShippingAddress.module.scss';
import {Address} from "../../utils/Address";
import AddressField from "../AddressField/AddressField";

type ShippingAddressProps = {
  address: Address;
}
const ShippingAddress: FC<ShippingAddressProps> = ({address}) => {
  return (
    <div className={classes.shippingAddress}>
      <div className={classes.title}>Dina uppgifter</div>
      <div className={classes.box}>
        <div className={classes.fields}>
          <AddressField label="Förnamn" value={address.firstName}/>
          <AddressField label="Efternamn" value={address.lastName}/>
          <AddressField label="E-post" value={address.email}/>
          <AddressField label="Mobiltelefon" value={address.mobile}/>
          <AddressField label="Adress" value={address.address}/>
          <AddressField label="Postnummer" value={address.zip}/>
          <AddressField label="Ort" value={address.city}/>
          <AddressField label="Land" value={address.country.name}/>
        </div>
      </div>
    </div>
  );
}

export default ShippingAddress;
