export const HOME = 'HOME';
export const ORDER = 'ORDER';
export const PRINT = 'PRINT';
export const HOME_TITLE = 'HOME_TITLE';
export const HOME_INFORMATION = 'HOME_INFORMATION';
export const HOME_PRODUCTS = 'HOME_PRODUCTS';

export const HOME_PRODUCT1_TITLE = 'HOME_PRODUCT1_TITLE';
export const HOME_PRODUCT1_DESCRIPTION = 'HOME_PRODUCT1_DESCRIPTION';

export const HOME_PRODUCT2_TITLE = 'HOME_PRODUCT2_TITLE';
export const HOME_PRODUCT2_DESCRIPTION = 'HOME_PRODUCT2_DESCRIPTION';

export const HOME_PRODUCT3_TITLE = 'HOME_PRODUCT3_TITLE';
export const HOME_PRODUCT3_DESCRIPTION = 'HOME_PRODUCT3_DESCRIPTION';

export const HOME_PRODUCT4_TITLE = 'HOME_PRODUCT4_TITLE';
export const HOME_PRODUCT4_DESCRIPTION = 'HOME_PRODUCT4_DESCRIPTION';

export const HOME_PRODUCT5_TITLE = 'HOME_PRODUCT5_TITLE';
export const HOME_PRODUCT5_DESCRIPTION = 'HOME_PRODUCT5_DESCRIPTION';

export const HOME_PRODUCT6_TITLE = 'HOME_PRODUCT6_TITLE';
export const HOME_PRODUCT6_DESCRIPTION = 'HOME_PRODUCT6_DESCRIPTION';

export const HOME_PRODUCT7_TITLE = 'HOME_PRODUCT7_TITLE';
export const HOME_PRODUCT7_DESCRIPTION = 'HOME_PRODUCT7_DESCRIPTION';

export const HOME_PRODUCTS_NOTE = 'HOME_PRODUCTS_NOTE';

export const ORDER_TITLE = 'ORDER_TITLE';
export const ORDER_DESCRIPTION = 'ORDER_DESCRIPTION';
export const ORDER_NOTE = 'ORDER_NOTE';
export const SHIPPING_NOTE = 'SHIPPING_NOTE';

export const PRINT_DESCRIPTION = 'PRINT_DESCRIPTION';