import React, {useEffect, useMemo, useRef, useState} from 'react';
import classes from './OrderPage.module.scss';
import OrderTable from "../../components/OrderTable/OrderTable";
import {useAuth} from "../../utils/Auth";
import {buyOrder, getOrder, orderConfirmation} from "../../utils/api";
import InnerHTML from 'dangerously-set-html-content';
import {useNavigate, useSearchParams} from "react-router-dom";
import OrderCheckout from "../../components/OrderCheckout/OrderCheckout";
import ShippingAddress from "../../components/ShippingAddress/ShippingAddress";
import {Order} from "../../utils/Order";
import {useContent} from "../../hooks/useContent";
import {ORDER, ORDER_DESCRIPTION, ORDER_NOTE, ORDER_TITLE} from "../../utils/pages";

const OrderPage = () => {
  const [order, setOrder] = useState<Order>();
  const [paymentHtml, setPaymentHtml] = useState();
  const [confirmation, setConfirmation] = useState(false);
  const [content] = useContent(ORDER);
  const {authToken} = useAuth();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const paymentHtmlRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (paymentHtml && paymentHtmlRef.current) {
      // paymentHtmlRef.current.scrollTo(0, 0);
      paymentHtmlRef.current.scrollIntoView();
    }
  }, [paymentHtml, paymentHtmlRef]);

  const klarnaOrderId = useMemo(() => searchParams.get('klarna_order_id'), [searchParams]);
  useEffect(() => {
    const confirmAndUpdate = async () => {
      if (klarnaOrderId) {
        if (!authToken) {
          return;
        }

        const confirmRet = await orderConfirmation(klarnaOrderId, authToken);

        if (confirmRet === null) {
          navigate("/", {state: { logout: true}});
        } else {
          const {htmlSnippet, order: confirmationOrder} = confirmRet;
          if (htmlSnippet) {
            setPaymentHtml(htmlSnippet);
          }
          setConfirmation(true);
          setOrder(confirmationOrder);
        }
      } else {
        await updateOrder();
      }
    };

    confirmAndUpdate();
  }, [klarnaOrderId]);

  const updateOrder = async () => {
    if (!authToken) {
      return;
    }

    const order = await getOrder(authToken);

    if (order === null) {
      navigate("/", {state: { logout: true}});
    } else {
      setPaymentHtml(undefined);
      setOrder(order);
    }
  }
  const handleOrderUpdated = async () => {
    await updateOrder();
  }

  const handleCheckout = async () => {
    if (confirmation || !authToken) {
      return;
    }

    setPaymentHtml(undefined);

    const newOrderHtml = await buyOrder(authToken);

    if (newOrderHtml === null) {
      navigate("/", {state: { logout: true}});
    } else {
      setPaymentHtml(newOrderHtml);
    }
  }

  return (
    <div className={classes.orderPage}>
      <div className={classes.main}>
        <h1 className={classes.title}>{content[ORDER_TITLE] || 'Beställning'}</h1>
        <h2 className={classes.subtitle}>
          {
            content[ORDER_DESCRIPTION] ||
            `Ladda upp PDF-filer för de kartor du vill beställa och ange antal. Det går att beställa alla storlekar
              av kartor med vilken PDF-fil som helst. Du kan även ladda upp samma fil för de olika kartstorlekarna.
              Stora kartor behöver dock högre upplösning på filen. Om du är osäker på om PDFen har tillräcklig upplösning
              så zooma in den så att kartan blir lika stor på skärmen som i verkligheten.
              Gå vidare till beställning genom att klicka på GÅ TILL CHECKOUT. Pengarna dras av Klarna först när vi
              skickar kartorna.`
          }
        </h2>
        <OrderTable order={order} onOrderUpdated={handleOrderUpdated} disabled={confirmation} />
      </div>
      <div className={classes.description}>
        <p>
          {
            content[ORDER_NOTE] ||
            ` För att hålla nere priset, trycker vi endast kartor fem gånger per år.
            Beställ senast den 15/6, 15/8, 5/9, 22/9 eller 1/11. Kartorna levereras senast 10 dagar efter dessa datum.
            Beställer du efter ett av dessa datum, kommer leveransen senast 10 dagar efter nästkommande datum.`
          }
        </p>
      </div>

      {order && order.items && order.items.length > 0 && (
        <div className={classes.orderCheckoutRow}>
          {confirmation && order?.shippingAddress && <ShippingAddress address={order?.shippingAddress} />}
          <OrderCheckout className={classes.orderCheckout} order={order} onCheckout={handleCheckout} />
        </div>
      )}

      {
        paymentHtml && (
          <div className={classes.payment} ref={paymentHtmlRef}>
            <h2 className={classes.paymentTitle}>Betalning</h2>
            <InnerHTML html={paymentHtml || ''}/>
          </div>
        )
      }
    </div>
  );
}

export default OrderPage;
