import React, {FC} from 'react';
import classes from './ProductsList.module.scss';
import ProductDescription from "../ProductDescription/ProductDescription";
import Button from "../Button/Button";
import {ReactComponent as ArrowSvg} from 'images/arrow.svg';
import cn from "classnames";
import ProductImage1 from 'images/products/1.jpg';
import ProductImage2 from 'images/products/2.jpg';
import ProductImage3 from 'images/products/3.jpg';
import ProductImage4 from 'images/products/4.jpg';
import ProductImage5 from 'images/products/5.jpg';
import ProductImage6 from 'images/products/6.jpg';
import ProductImage7 from 'images/products/7.jpg';
import {
  HOME_PRODUCT1_DESCRIPTION,
  HOME_PRODUCT1_TITLE,
  HOME_PRODUCT2_DESCRIPTION,
  HOME_PRODUCT2_TITLE,
  HOME_PRODUCT3_DESCRIPTION,
  HOME_PRODUCT3_TITLE,
  HOME_PRODUCT4_DESCRIPTION,
  HOME_PRODUCT4_TITLE,
  HOME_PRODUCT5_DESCRIPTION,
  HOME_PRODUCT5_TITLE,
  HOME_PRODUCT6_DESCRIPTION,
  HOME_PRODUCT6_TITLE,
  HOME_PRODUCT7_DESCRIPTION,
  HOME_PRODUCT7_TITLE
} from "../../utils/pages";

type ProductsListProps = {
  content?: any;
  onLogin: () => void;
  onScroll: () => void;
  className?: string;
};

const ProductsList: FC<ProductsListProps> = ({content, onLogin, onScroll, className}) => {
  return (
    <div className={classes.descriptions}>
      <div className={classes.wrapper}>
        <ProductDescription
          title={(content && content[HOME_PRODUCT1_TITLE]) || 'PVC-duk'}
          description={(content && content[HOME_PRODUCT1_DESCRIPTION]) || 'Vädertålig duk som inte skrynklar eller prasslar. Material som i ”glassflaggor”. Alla i jaktlaget kan ha en A4 eller A3 i fickan. Rulla ut en A0 på motorhuven innan jakten.'}
          imageUrl={ProductImage1}
        />
        <ProductDescription
          title={(content && content[HOME_PRODUCT2_TITLE]) || 'Magnetisk duk'}
          description={(content && content[HOME_PRODUCT2_DESCRIPTION]) || '1 mm tjock duk som fäster på bilplåten. Magnetpluppar som visar pass och jägare fastnar på duken.'}
          imageUrl={ProductImage2}
        />
        <ProductDescription
          title={(content && content[HOME_PRODUCT3_TITLE]) || 'Whiteboard klisterdekal'}
          description={(content && content[HOME_PRODUCT3_DESCRIPTION]) || 'Klistras på t.ex. hundkåpan. Text från whitboardpennor kan suddas. Ej magnetisk.'}
          imageUrl={ProductImage3}
        />
        <ProductDescription
          title={(content && content[HOME_PRODUCT4_TITLE]) || 'Whiteboard plastskiva'}
          description={(content && content[HOME_PRODUCT4_DESCRIPTION]) || 'Styv 3 mm tjock plastskiva. Text från whitboardpennor kan suddas. Ej magnetisk.'}
          imageUrl={ProductImage4}
        />
        <ProductDescription
          title={(content && content[HOME_PRODUCT5_TITLE]) || 'Whiteboard magnetisk'}
          description={(content && content[HOME_PRODUCT5_DESCRIPTION]) || 'Samma som på jobbet. Text från whitboardpennor kan suddas. Magnetpluppar fastnar.'}
          imageUrl={ProductImage5}
        />
        <ProductDescription
          title={(content && content[HOME_PRODUCT6_TITLE]) || 'Whiteboardpennor & sudd'}
          description={(content && content[HOME_PRODUCT6_DESCRIPTION]) || 'Röd, blå, grön och svart penna samt sudd. Rita var hundförare går och annat och sudda. OBS: Använd ej på PVC-duk och Magnetisk duk.'}
          imageUrl={ProductImage6}
        />
        <ProductDescription
          title={(content && content[HOME_PRODUCT7_TITLE]) || '65 Magnetpluppar'}
          description={(content && content[HOME_PRODUCT7_DESCRIPTION]) || '3 röda för hundförare, 12 gula för passkyttar, 25 blå och 25 lila för olika pass (t.ex. torn och markpass). För kartor utan pass.'}
          imageUrl={ProductImage7}
        />
        <div className={cn(className, classes.informationButtons)}>
          <Button className={classes.loginButton} label="Beställ nu" onClick={onLogin} image={<ArrowSvg />}/>
        </div>

      </div>
    </div>
  );
}

export default ProductsList;
