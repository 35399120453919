import React, {FC} from 'react';
import Modal from 'react-modal';

import classes from './ConfirmInvoiceModal.module.scss';
import Button from "../Button/Button";
import {priceFormat} from "../../utils/priceFormat";

type ConfirmInvoiceModalProps = {
  isOpen: boolean;
  numberOfOrders: number;
  totalValue: number;
  onConfirm: () => void;
  onCancel: () => void;
}
const ConfirmInvoiceModal: FC<ConfirmInvoiceModalProps> = ({ isOpen, numberOfOrders, totalValue, onConfirm, onCancel}) => {

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      className={classes.confirmInvoiceModal}
    >
      Do you really want to invoice the selected {numberOfOrders} orders at a total value of {priceFormat(totalValue)} kr excl. VAT to Jaktkarta.se?

      <div className={classes.buttons}>
        <Button label='Cancel' className={classes.cancelButton} onClick={() => onCancel()}/>
        <Button label='Ok' onClick={() => onConfirm()}/>
      </div>
    </Modal>
  );
}

export default ConfirmInvoiceModal;
