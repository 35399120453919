import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";

import './index.scss';
import reportWebVitals from './reportWebVitals';
import OrderPage from "./pages/OrderPage/OrderPage";
import App from "./App";
import AdminPage from "./pages/AdminPage/AdminPage";
import PrintPage from "./pages/PrintPage/PrintPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import CookiePolicyPage from "./pages/CookiePolicyPage/CookiePolicyPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsOfPurchasePage from "./pages/TermsOfPurchasePage/TermsOfPurchasePage";
import HomeAdminPage from "./pages/HomeAdminPage/HomeAdminPage";
import HomePage from "./pages/HomePage/HomePage";
import OrderAdminPage from "./pages/OrderAdminPage/OrderAdminPage";
import PrintAdminPage from "./pages/PrintAdminPage/PrintAdminPage";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "login/:token",
        element: <HomePage />,
      },
      {
        path: "order",
        element: <OrderPage />
      },
      {
        path: "/order/confirmation",
        element: <OrderPage />
      },
      {
        path: "print",
        element: <PrintPage />
      },
      {
        path: "admin",
        element: <AdminPage />
      },
      {
        path: "home/admin",
        element: <HomeAdminPage />
      },
      {
        path: "order/admin",
        element: <OrderAdminPage />
      },
      {
        path: "print/admin",
        element: <PrintAdminPage />
      },
      {
        path: "contact",
        element: <ContactPage />
      },
      {
        path: "about",
        element: <AboutPage />
      },
      {
        path: "cookie-policy",
        element: <CookiePolicyPage />
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />
      },
      {
        path: "terms-of-purchase",
        element: <TermsOfPurchasePage  />
      }
    ]
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
