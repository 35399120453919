import {createContext, useContext} from "react";

interface LoginContextType {
  authToken: string | null;
  setAuthToken: (token: string) => void;
}

export const AuthContext = createContext<LoginContextType>({
  authToken: null,
  setAuthToken: () => {}
});

export function useAuth() {
  return useContext(AuthContext);
}