import React, {FC, FormEvent, useState} from 'react';
import Modal from 'react-modal';
import {ReactComponent as CloseSvg} from 'images/close.svg';

import classes from './LoginModal.module.scss';
import Button from "../Button/Button";
import {register} from "../../utils/api";

const LAST_EMAIL = 'lastEmail';

type LoginModalProps = {
  isOpen: boolean;
  onClose?: () => void;
}
const LoginModal: FC<LoginModalProps> = ({ isOpen, onClose}) => {
  const [email, setEmail] = useState(localStorage.getItem(LAST_EMAIL) || '');
  const [sent, setSent] = useState(false);
  const handleLogin = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if(email) {
      await register(email);
      localStorage.setItem(LAST_EMAIL, email);
      setSent(true);
    }

    return false;
  }

  const handleClose = () => {
    setSent(false);
    setEmail('');

    onClose && onClose();
  }
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      className={classes.loginModal}
      style={
        {
          overlay: {
            zIndex: 10
          }
        }
      }
    >
      <CloseSvg className={classes.closeButton} onClick={handleClose}/>
      <div className={classes.title}>Logga in</div>
      {
        sent? (
          <div className={classes.container}>
            <div className={classes.text}>
              Vi har skickat e-post med en inloggningslänk till {email}. Öppna e- posten och klicka på länken
              för att gå vidare. Om du inte får någon e-post så titta i din skräppostmapp.
            </div>
          </div>
        ) : (
          <form className={classes.main} onSubmit={handleLogin}>
            <div className={classes.text}>Ange din e-postadress</div>
            <input name="login" className={classes.input} value={email} onChange={(e) => setEmail(e.target.value) } type="email"/>
            <Button label='Skicka inloggningslänk' className={classes.button} onClick={() => handleLogin()}/>
          </form>
        )
      }
    </Modal>
  );
}

export default LoginModal;
