import React from 'react';
import StaticContent from "components/StaticContent/StaticContent";

const TermsOfPurchasePage = () => {
  return (
    <StaticContent
      title="Köpvillkor"
      paragraphs={[
        {
          title: "Leveranstid",
          text: "Leveranstid för beställning i augusti - september är 1-2 veckor, för januari - maj 6 veckor och övrig tid 3 veckor."
        },
        {
          title: "Betalningsalternativ",
          text: "I samarbete med Klarna erbjuder vi fakturabetalning, delbetalning, kortbetalning samt direktbetalning. " +
            "Fakturering och betalning via kort sker först när dina kartor skickas från oss. " +
            "Fakturor skickas via e-post och skall betalas inom 14 dagar. Fullständiga villkor om Klarnas betalsätt hittar du på Klarna.se"
        },
        {
          title: "Frakt",
          text: "Kostnaden för frakten redovisas i kassan. Vi tar inte ekonomiskt ansvar för eventuella förseningar som beror på fraktföretaget. " +
            "För returnerade produkter som inte har några fel står kunden för frakten."
        },
        {
          title: "Ångerrätt och reklamation",
          text:
            <>
              Du har rätt att ångra ditt köp utan att ange något skäl inom 14 dagar efter leverans.
              I så fall kontaktar du oss på e-post <a href="mailto:info@jaktkarta.se">info@jaktkarta.se</a>. Om kartan är felaktig så bifoga en bild.
              Skicka inte in några kartor till oss utan att du fått instruktioner om detta.
            </>
        }
      ]}
    />
  );
}

export default TermsOfPurchasePage;