import React, {useEffect, useMemo, useState} from 'react';
import classes from './PrintPage.module.scss';
import {orderStatus} from "../../utils/orderStatus";
import {Address} from "../../utils/Address";
import {addOrderComment, 
        deleteOrderComment, 
        getOrdersByStatus, 
        moveOrders, 
        editOrder, 
        saveOrder, 
        cancelEditOrder
        } from "../../utils/api";
import {useAuth} from "../../utils/Auth";
import PrintOrder from "../../components/PrintOrder/PrintOrder";
import PrintOrderEdit from "../../components/PrintOrderEdit/PrintOrderEdit";
import Button from "../../components/Button/Button";
import ConfirmInvoiceModal from "../../components/ConfirmInvoiceModal/ConfirmInvoiceModal";
import {Order} from "../../utils/Order";
import {useNavigate} from "react-router-dom";
import {ReactComponent as CheckSvg} from 'images/check.svg';
import {useContent} from "../../hooks/useContent";
import {HOME_PRODUCTS, PRINT, PRINT_DESCRIPTION} from "../../utils/pages";
import {downloadFile} from "../../utils/api";

const PrintPage = () => {
  const [orders, setOrders] = useState<any>({});
  const [downloading, setDownloading] = useState<Array<number>>([]);
  const [selectedShipped, setSelectedShipped] = useState<any>({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [content] = useContent(PRINT);

  const {authToken} = useAuth();
  const navigate = useNavigate();

  const fetchAllOrders = async () => {
    if (!authToken) {
      return;
    }

    for (let status of orderStatus) {
      const ordersByStatus = await getOrdersByStatus(status, authToken);

      if (ordersByStatus === null) {
        navigate("/", {state: { logout: true}});
        break;
      } else {
        if (status === 'SHIPPED') {
          const unselectedShipped = ordersByStatus.reduce((acc: any, cur: any) => (
            {
              ...acc,
              [cur.id]: false
            }
          ), {});
          setSelectedShipped(unselectedShipped);
        }
        setOrders((prevOrders: any) => ({
            ...prevOrders,
            [status]: ordersByStatus
          })
        );
      }
    }
  }

  useEffect(() => {
    fetchAllOrders();
  }, []);

  const handleMove = async (orderId: number) => {
    if (!authToken) {
      return;
    }

    const ret = await moveOrders([orderId], authToken);

    if (ret === null) {
      navigate("/", {state: { logout: true}});
    } else {
      await fetchAllOrders();
    }
  }

  const handleEditOrder = async (orderId: number) => {
    if (!authToken) {
      return;
    }

    const ret = await editOrder(orderId, authToken);

    if (ret === null) {
      navigate("/", {state: { logout: true}});
    } else {
      await fetchAllOrders();
    }
  }

  const handleSaveOrder = async (orderId: number, address: Address) => {
    if (!authToken) {
      return;
    }

    const ret = await saveOrder(orderId, authToken, address);

    if (ret === null) {
      navigate("/", {state: { logout: true}});
    } else {
      await fetchAllOrders();
    }
  }

  const handleCancelEditOrder = async (orderId: number) => {
    if (!authToken) {
      return;
    }

    const ret = await cancelEditOrder(orderId, authToken);

    if (ret === null) {
      navigate("/", {state: { logout: true}});
    } else {
      await fetchAllOrders();
    }
  }

  const handleAddComment = async (comment: string, orderId: number) => {
    if (!authToken) {
      return;
    }

    const ret = await addOrderComment(comment, orderId, authToken);

    if (ret === null) {
      navigate("/", {state: { logout: true}});
    } else {
      await fetchAllOrders();
    }
  }

  const handleDeleteComment = async (commentId: number) => {
    if (!authToken) {
      return;
    }

    const ret = await deleteOrderComment(commentId, authToken);

    if (ret === null) {
      navigate("/", {state: { logout: true}});
    } else {
      await fetchAllOrders();
    }
  }

  const handleDownloadOrdered = async () => {
    if (!authToken) {
      return;
    }

    setDownloading([...downloading, 1]);
    const ret = await downloadFile('/api/orders/download-ordered', 'JaktkartaDeliveryNotesAndFiles.zip', authToken);

    setDownloading(downloading.filter(d => d !== 1));

    if (ret === null) {
      navigate("/", {state: { logout: true}});
    }
  }
  
  const handleInvoiceSelected = async () => {
    const orderIds = Object.entries(selectedShipped).filter(e => e[1]).map(e => +e[0]);
    if (orderIds.length > 0) {
      setConfirmModalOpen(true);
    }
  }

  const handleConfirmInvoice = async () => {
    if (!authToken) {
      return;
    }

    const orderIds = Object.entries(selectedShipped).filter(e => e[1]).map(e => +e[0]);
    const ret = await moveOrders(orderIds, authToken);
    if (ret === null) {
      navigate("/", {state: { logout: true}});
    } else {
      setConfirmModalOpen(false);
      await fetchAllOrders();
    }
  }

  const [selectedInvoiced, totalInvoiced] = useMemo(() => {
    const orderIds = Object.entries(selectedShipped).filter(e => e[1]).map(e => +e[0]);
    const total = orderIds.reduce((acc, curr) => {
      const order: Order = orders['SHIPPED'].find((o: any) => o.id === curr);
      return acc + (order ? order.printAmount : 0);
    }, 0);

    return [orderIds.length, total];
  }, [selectedShipped, orders]);

  const shippedChecked = useMemo(() => {
    return Object.values(selectedShipped).every(f => f);
  }, [selectedShipped])

  const toggleShippedChecked = () => {
    const checked = Object.values(selectedShipped).every(f => f);

    const newSelectedShipped = {
      ...selectedShipped
    }

    Object.keys(newSelectedShipped).forEach((k) => newSelectedShipped[k] = !checked);

    setSelectedShipped(newSelectedShipped);
  }

  return (
    <div className={classes.printPage}>
      {
        content && content[PRINT_DESCRIPTION] ? (
          <div className={classes.description} dangerouslySetInnerHTML={{__html: (content[PRINT_DESCRIPTION]) || ''}}></div>
        ) : (
          <div className={classes.description}>
            <p>
              <strong>ORDERED</strong>: Här visas alla beställningar som inte är behandlade.
              <strong>OBS</strong>: Dessa är sista beställningsdatum:
              <strong>15/6, 15/8, 5/9, 22/9 eller 1/11</strong>. Senast inom 2-4 arbetsdagar efter varje datum skall alla kartor tryckas och skickas. Man kan trycka kartorna innan dessa datum, men man måste då kolla denna kolumn dagen efter ett sista beställningsdatum så att man får med sent inkomna beställningar. Ni får gärna trycka och skicka kartor innan dessa sista beställningsdatum, men då måste ni också trycka och skicka efter varje sista beställningsdatum. Du ser typ av karta, storlek, antal och PDF-fil för att trycka kartan. Tillbehör har ingen fil. Eventuell röd text är en specialorder. Längst ned ser du leveransadress samt mobilnummer för avisering. Om du av någon anledning behöver kontakta en kund direkt, säg att du jobbar på Jaktkarta.se. När en order är skickad med Bring, klicka på knappen ”MOVE TO SHIPPED”. Då är beställningen behandlad och hoppar över till nästa rad.
            </p>
            <p>
              <strong>SHIPPED</strong>: Här ligger alla beställningar som är skickade men som Hotfrog inte har fakturerat. När ni vill fakturera så klicka i ”SELECT ALL” och sedan på knappen ”INVOICE SELECTED” och bekräfta sedan med OK. Då hoppar alla märkta beställningar över till kolumn INVOICED och är då helt klara. Samtidigt skickas ett mail till Jonas med ett fakturaunderlag i Excel på alla dessa beställningar. Jonas kan då bara skriva ut detta fakturaunderlag och bifoga till en faktura. Fakturatexten ”Se bilaga” räcker.
            </p>
            <p>
              <strong>INVOICED</strong>: Dessa är klara men ligger kvar i fall om att. Om en kund klagar och vi beslutar att skicka om delar eller hela beställningen, klicka på knappen ”MOVE TO COMPLAINT”. COMPLAINT: När vi skickat om en beställning eller den del som var fel, klicka på ”MOVE TO INVOICED”. Försök hålla denna kolumn så tom som möjligt.
            </p>
          </div>
        )
      }


      <div className={classes.ordersTable}>
        <div className={classes.col}>
          <div className={classes.ordered}>
            <div className={classes.title}>ORDERED</div>
            {
              downloading.includes(1) ? (
                  <span className={classes.loader}></span>
              ) : (
                  <Button className={classes.downloadOderedButton} label='Download PDFs & Delivery notes' onClick={handleDownloadOrdered}/>
              )
            }
          </div>
          {
            (orders['ORDERED'] || []).map((o: any) => (
              o.edited ? ( 
                  <PrintOrderEdit
                    key={o.id}
                    order={o}
                    onSaveOrder={handleSaveOrder}
                    onCancelEdit={() => handleCancelEditOrder(o.id)}                    
                  />
              ) : (
                  <PrintOrder
                    key={o.id}
                    order={o}
                    buttonLabel='Move to Shipped'
                    onAction={() => handleMove(o.id)}
                    onAddComment={(comment) => handleAddComment(comment, o.id)}
                    onDeleteComment={(commentId) => handleDeleteComment(commentId)}
                    onEditOrder={() => handleEditOrder(o.id)}
                  />
              )
            ))
          }
        </div>
        <div className={classes.col}>
          <div className={classes.shipped}>
            <div>Select all</div>
            <div className={classes.check} onClick={toggleShippedChecked}>

              {
                shippedChecked && <CheckSvg />
              }
            </div>
            <div className={classes.title}>SHIPPED</div>
            <Button label='Invoice selected' onClick={handleInvoiceSelected}/>
          </div>
          {
            (orders['SHIPPED'] || []).map((o: any) =>
              <PrintOrder
                key={o.id}
                order={o}
                checked={selectedShipped[o.id]}
                onToggle={() => setSelectedShipped({...selectedShipped, [o.id]: !selectedShipped[o.id]})}
                onAddComment={(comment) => handleAddComment(comment, o.id)}
                onDeleteComment={(commentId) => handleDeleteComment(commentId)}                
              />

            )
          }
        </div>
        <div className={classes.col}>
          <div className={classes.title}>INVOICED</div>
          {
            (orders['INVOICED'] || []).map((o: any) => (
              <PrintOrder
                key={o.id}
                order={o}
                buttonLabel='Move to Complaint'
                onAction={() => handleMove(o.id)}
                onAddComment={(comment) => handleAddComment(comment, o.id)}
                onDeleteComment={(commentId) => handleDeleteComment(commentId)}                
              />
            ))
          }
        </div>
        <div className={classes.col}>
          <div className={classes.title}>COMPLAINT</div>
          {
            (orders['COMPLAINT'] || []).map((o: any) => (
              o.edited ? ( 
                  <PrintOrderEdit
                    key={o.id}
                    order={o}
                    onSaveOrder={handleSaveOrder}
                    onCancelEdit={() => handleCancelEditOrder(o.id)}                    
                  />
              ) : (
                  <PrintOrder
                    key={o.id}
                    order={o}
                    buttonLabel='Move to Invoiced'
                    onAction={() => handleMove(o.id)}
                    onAddComment={(comment) => handleAddComment(comment, o.id)}
                    onDeleteComment={(commentId) => handleDeleteComment(commentId)}
                    onEditOrder={() => handleEditOrder(o.id)}
                  />
              )
            ))
          }
        </div>
        <ConfirmInvoiceModal
          isOpen={confirmModalOpen}
          numberOfOrders={selectedInvoiced}
          totalValue={totalInvoiced}
          onCancel={() => setConfirmModalOpen(false)}
          onConfirm={handleConfirmInvoice}
        />
      </div>
    </div>
  );
}

export default PrintPage;
