import React, {FC, useEffect, useRef, useState} from 'react';

import {ReactComponent as UploadSvg} from 'images/upload.svg';
import {ReactComponent as DeleteSvg} from 'images/delete.svg';

import classes from './OrderItemRow.module.scss';
import {priceFormat} from "../../utils/priceFormat";

type OrderItemRowProps = {
  orderItem: any;
  disabled: boolean;
  onAddOrderItem: (product: string, mapSize: string, quantity: number, file?: File) => void;
  onUpdateQuantity: (orderItemId: number, newQuantity: number) => void;
  onDeleteOrderItem: (orderItemId: number) => void;
  onDownload: (downloadPath: string, fileName: string) => void
}
const OrderItemRow: FC<OrderItemRowProps> = ({orderItem, disabled, onAddOrderItem, onUpdateQuantity, onDeleteOrderItem, onDownload}) => {
  const [quantity, setQuantity] = useState(orderItem?.quantity || 0);
  const uploadFileRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (disabled) {
      return;
    }
    uploadFileRef.current?.click();
  };

  const handleAddClick = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);

    if (orderItem.id) {
      onUpdateQuantity(orderItem.id, newQuantity);
    } else if (!orderItem.fileRequiring) {
      onAddOrderItem(orderItem.product, orderItem.mapSize, newQuantity);
    }
  }

  const handleSubtractClick = () => {
    const newQuantity = Math.max(quantity - 1, 0);
    setQuantity(newQuantity);

    if (orderItem.id) {
      onUpdateQuantity(orderItem.id, newQuantity);
    }
  }

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }

    if (uploadFileRef.current?.files?.length) {
      const file = uploadFileRef.current?.files[0];
      onAddOrderItem(orderItem.product, orderItem.mapSize, quantity <= 0 ? 1 : quantity, file);
    }
  }

  useEffect(() => {
    if(orderItem?.quantity) {
      setQuantity(orderItem.quantity);
    }
  }, [orderItem]);

  const handleDownloadClick = () => {
    if (orderItem?.downloadPath && orderItem?.fileName) {
      onDownload && onDownload(orderItem?.downloadPath, orderItem?.fileName)
    }
  }

  const handleUpdateQuantity = (value: number) => {
    setQuantity(value);
  }

  const handleOnBlur = () => {
    if (orderItem.fileRequiring) {
      return;
    }

    onAddOrderItem(orderItem.product, orderItem.mapSize, quantity <= 0 ? 1 : quantity);
  }

  return (
    <tr className={classes.orderItemRow}>
      <td className={classes.productName}>{orderItem.mapSize}</td>
      <td className={classes.imageCell} align="center">
        {
          orderItem.fileRequiring ? (
            <div className={classes.uploadButton} onClick={handleUploadClick}>
              <UploadSvg className={classes.img}/>
              <input
                type="file"
                ref={uploadFileRef}
                style={{display: "none"}}
                accept="application/pdf"
                onChange={handleOnFileChange}
                disabled={!!orderItem.id}
              />
            </div>
          ) : (
            <div>&nbsp;</div>
          )
        }
      </td>
      <td className={classes.fileNameCell} onClick={handleDownloadClick}>{orderItem.fileName || ''}</td>
      <td className={classes.imageCell}>
        {
          orderItem.fileRequiring ? (
            <button className={classes.deleteButton} onClick={(id) => orderItem.id && onDeleteOrderItem(orderItem.id)}>
              <DeleteSvg className={classes.img}/>
            </button>
          ) : (
            <div>&nbsp;&nbsp;</div>
          )
        }
      </td>
      <td>{priceFormat(orderItem.price)} kr</td>
      <td className={classes.quantityRow}>
        <button className={classes.addButton} onClick={handleSubtractClick}>
          -
        </button>
        <input
          type="number"
          disabled={!!orderItem.id}
          className={classes.quantity}
          min={0}
          value={quantity}
          onChange={(e) => handleUpdateQuantity(e.target.valueAsNumber)}
          onBlur={handleOnBlur}
        />
        <button className={classes.addButton} onClick={handleAddClick}>
          +
        </button>
      </td>
      <td>{priceFormat(orderItem.amount || (orderItem.price * quantity))} kr</td>
    </tr>
  );
}

export default OrderItemRow;
