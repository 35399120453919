import React, {FC} from 'react';

import {SentEmail} from "../../utils/SentEmail";
import classes from './SentEmailTable.module.scss';
import InnerHTML from 'dangerously-set-html-content';

type SentEmailTableProps = {
  emails: Array<SentEmail>;
}
const SentEmailTable: FC<SentEmailTableProps> = ({emails}) => {
  return (
      <table className={classes.table}>
        <tbody>
        {
          emails.map(p => (
              <tr>
                <td>
                    <div className={classes.email}>
                        <div className={classes.sentDate}>{p.sentDate}</div>
                        <div className={classes.subject}>{p.subject}</div>
                        <div className={classes.body}>
                            <InnerHTML html={p.body || ''}/>
                        </div>
                    </div>
                </td>
              </tr>
          ))
        }
        </tbody>
      </table>
  );
}

export default SentEmailTable;
