import React from 'react';
import StaticContent from "components/StaticContent/StaticContent";

const PrivacyPolicyPage = () => {
  return (
    <StaticContent
      title="Integritetspolicy"
      paragraphs={[
        {
          title: "Lagring av personuppgifter",
          text:
            <>
              <p>
                Tjänsten Jaktkarta.se drivs av Timeslot AB, org.nr 559423-2158. I texten nedan skrivs tjänstens namn
                Jaktkarta.se, men juridiskt sett menas Timeslot AB.
              </p>
              <p>
                Jaktkarta.se sparar personuppgifter för att kunna leverera produkter till rätt adress, för att kunna
                svara på supportfrågor och för att nu kunden med epost med marknadsföring. Jaktkarta.se är
                Personuppgiftsansvarig för dessa uppgifter. Jaktkarta.se sparar kundens uppgifter tills dess att kunden,
                via epost till <a href="mailto:info@jaktkarta.se">info@jaktkarta.se</a>, begär att detta ska raderas.
              </p>
              <p>
                Jaktkarta.se använder underleverantörer för att kunna erbjuda praktiska och säkra tjänster.
                Dessa underleverantörer är alla stora etablerade företag såsom Klarna, Amazon AWS och Swedbank/BankID.
                Alltså företag som bör ha väl fungerande rutiner för att följa GDPR och lagring av personuppgifter.
              </p>
            </>
        },
        {
          title: "Datasäkerhet",
          text: <>
            Jaktkarta.se förvaltar själva hela tjänsten, det tekniska systemet och databasen för Jaktkarta.se.
            Ingen förutom underleverantörerna som nämnts ovan har åtkomst till personuppgifterna.
            Jaktkarta.se behandlar personlig data enligt följande:
            <ul>
              <li>Samtliga anställda har genomgått säkerhets- och personuppgiftsutbildning.</li>
              <li>Endast de anställda som behöver ha tillgång till vissa personuppgifter har det.</li>
              <li>Endast ett fåtal nyckelpersoner har kunskap om hur säkerhetssystemet är uppbyggt.</li>
              <li> Lagring av data sker på världens största lagringstjänst Amazon AWS,
                bland annat för att undvika risken för att personlig data förstörs eller kommer på avvägar.</li>
            </ul>
          </>
        },
        {
          title: "Registrerades rättigheter",
          text: "En person som har registrerats av Jaktkarta.se har ett antal rättigheter enligt Dataskyddsförordningen. " +
            "En registrerad har, på det sätt som föreskrivs i lag, rätt att få information om vilka personuppgifter " +
            "Jaktkarta.se har lagrat om denne genom ett registerutdrag. Den registrerade har vidare rätt till rättelse, " +
            "eller radering av dennes personuppgifter. Vid frågor eller påkallande av någon av dessa rättigheter, " +
            "kontakta Jaktkarta.se på info@jaktkarta.se."
        }
      ]}
    />
  );
}

export default PrivacyPolicyPage;