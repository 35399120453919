import React, {FC} from 'react';
import {ReactComponent as ArrowSvg} from 'images/arrow.svg';

import classes from './OrderCheckout.module.scss';
import Button from "../Button/Button";
import cn from "classnames";
import {priceFormat} from "../../utils/priceFormat";
import {Order} from "../../utils/Order";

type OrderCheckoutProps = {
  order: Order;
  onCheckout: () => void;
  className?: string;
}
const OrderCheckout: FC<OrderCheckoutProps> = ({order, onCheckout, className}) => {
  return (
    <div className={cn(classes.orderCheckout, className)}>
      <div className={classes.title}>Ordersummering</div>
      <div className={classes.box}>
        <div className={classes.row}>
          <div>Kostnad kartor:</div>
          <div>{priceFormat(order.mapAmount)} kr</div>
        </div>
        <div className={classes.row}>
          <div>Frakt:</div>
          <div>{priceFormat(order.shippingAmount)} kr</div>
        </div>
        <div className={classes.row}>
          <div>Totalt inkl. moms:</div>
          <div className={classes.bold}>{priceFormat(order.amount)} kr</div>
        </div>
        <div className={cn(classes.row, classes.rowNoBorder)}>
          <div>Moms:</div>
          <div>{priceFormat(order.taxAmount)} kr</div>
        </div>
        <Button
          label='Gå till checkout'
          className={classes.button}
          image={<ArrowSvg />}
          onClick={onCheckout}
        />
        <div className={classes.footerText}>
          Betalning sker snabbt och enkelt med Klarna.
        </div>
      </div>
    </div>
  );
}

export default OrderCheckout;
