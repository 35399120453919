import React, {FC} from 'react';
import cn from "classnames";

import classes from './InformationButtons.module.scss';
import Button from "../Button/Button";
import {ReactComponent as ArrowSvg} from 'images/arrow.svg';

type InformationButtonsProps = {
  onLogin: () => void;
  onScroll: () => void;
  className?: string;
};

const InformationButtons: FC<InformationButtonsProps> = ({onLogin, onScroll, className}) => {
  return (
    <div className={cn(className, classes.informationButtons)}>
      <Button className={classes.loginButton} label="Beställ nu" onClick={onLogin} image={<ArrowSvg />}/>
    </div>
  );
}

export default InformationButtons;
