import React from 'react';
import StaticContent from "components/StaticContent/StaticContent";

const CookiePolicyPage = () => {
  return (
    <StaticContent
      title="Cookie Policy"
      paragraphs={[
        {
          title: "Vad är en cookie?",
          text: "Cookies är små textfiler som lagras på en dator eller annan enhet när en användare besöker en hemsida. De innehåller viss information om användarens besök på hemsidan. Cookies är ofta nödvändiga för att möjliggöra en så bra användarupplevelse som möjligt eftersom det finns många praktiska webbplatsfunktioner som kräver cookies."
        },
        {
          title: "Hur använder Jaktkarta.se cookies?",
          text: <>
            Liksom de flesta andra webbsidor, använder Jaktkarta.se cookies. Detta sker i följande syften:
            <ul>
              <li>För att förbättra och förenkla din användarupplevelse av tjänsten. Ett exempel är att du kan välja att spara din inloggningsinformation för att enklare kunna logga in vid ditt nästa besök.</li>
              <li>För att eventuell marknadsföring är aktuell och relevant för dig.</li>
              <li>För att samla och analysera besöksstatistik och annan statistisk information om användandet av tjänsten.</li>
            </ul>
          </>
        },
        {
          title: "Hantering av cookies i din webbläsare",
          text: "Du kan alltid ändra inställningarna för cookies i din webbläsare. Var dock medveten om att sådana ändringar kan medföra att tjänsten inte fungerar lika smidigt."
        }
      ]}
    />
  );
}

export default CookiePolicyPage;