import React, {FC} from 'react';

import classes from './StaticContent.module.scss';
type Paragraph = {
  title: string;
  text: React.ReactNode | string;
}

type StaticContentProps = {
  title: string;
  primaryText?: React.ReactNode | string;
  paragraphs?: Array<Paragraph>
}
const StaticContent: FC<StaticContentProps> = ({title, primaryText, paragraphs}) => {
  return (
    <div className={classes.staticContent}>
      <div className={classes.container}>
        <div className={classes.primaryBox}>
          <h1 className={classes.title}>{title}</h1>
          {primaryText && <div className={classes.primaryText}>{primaryText}</div>}
        </div>
        {
          (paragraphs || []).map((p, index ) =>(
            <div key={index} className={classes.secondaryBox}>
              <h2 className={classes.subTitle}>{p.title}</h2>
              <div className={classes.secondaryText}>{p.text}</div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default StaticContent;
