import React, {FC} from 'react';

import cn from "classnames";
import classes from './Button.module.scss';

type ButtonProps = {
  label: string;
  image?: React.ReactNode;
  onClick?: () => void;
  className?: string;
}
const Button: FC<ButtonProps> = ({label, image, onClick, className}) => {
  return (
    <div className={cn(classes.button, className)} onClick={onClick}>
      {label}
      {image}
    </div>
  );
}

export default Button;
