import React from 'react';
import slideImage1 from 'images/slides/1.jpg';
import slideImage2 from 'images/slides/2.jpg';
import slideImage3 from 'images/slides/3.jpg';
import slideImage4 from 'images/slides/4.jpg';
import slideImage5 from 'images/slides/5.jpg';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import classes from  './Slideshow.module.scss';

const Slideshow = () => {
    return (
      <Slide prevArrow={<span/>} nextArrow={<span/>}>
        <div className={classes.eachSlideEffect}>
          <div style={{ 'backgroundImage': `url(${slideImage1})` }} />
        </div>
        <div className={classes.eachSlideEffect}>
          <div style={{ 'backgroundImage': `url(${slideImage2})` }} />
        </div>
        <div className={classes.eachSlideEffect}>
          <div style={{ 'backgroundImage': `url(${slideImage3})` }} />
        </div>
        <div className={classes.eachSlideEffect}>
          <div style={{ 'backgroundImage': `url(${slideImage4})` }} />
        </div>
        <div className={classes.eachSlideEffect}>
          <div style={{ 'backgroundImage': `url(${slideImage5})` }} />
        </div>
      </Slide>
    );
}

export default Slideshow;
