import React, {useEffect, useRef, useState} from 'react';
import classes from './AdminPage.module.scss';
import {getAdminSettings, getAllPageContent, getPageContent, updateAdminSettings, updatePage, downloadFile, findSentEmail} from "../../utils/api";
import {useAuth} from "../../utils/Auth";
import {AdminSettings, AdminSettingsAdminKeys} from "../../utils/AdminSettings";
import {MAP_SIZES} from "../../utils/Product";
import {SentEmail} from "../../utils/SentEmail";
import {useNavigate} from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {HOME, HOME_PRODUCTS} from "../../utils/pages";
import SentEmailTable from "../../components/SentEmailTable/SentEmailTable";
import Button from "../../components/Button/Button";

const AdminPage = () => {
  const [settings, setSettings] = useState<AdminSettings | undefined>();
  const [invoiceAdminEmail, setInvoiceAdminEmail] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [mapAdminEmail, setMapAdminEmail] = useState('');
  const [products, setProducts] = useState<Array<string>>([]);
  const [sentEmail, setSentEmail] = useState('');  
  const [sentEmails, setSentEmails] = useState<Array<SentEmail>>([]);
  const {authToken} = useAuth();
  const navigate = useNavigate();

  const fetchData = async () => {
    if (!authToken) {
      return;
    }

    const serverSettings = await getAdminSettings(authToken);

    if (serverSettings === null) {
      navigate("/", {state: { logout: true}});
    } else {
      const serverProducts = (serverSettings?.price ?? []).reduce((c: Array<any>, p) => {if (p && !c.includes(p.product)) c.push(p.product); return c;}, []);

      setProducts(serverProducts);

      setSettings(serverSettings);
    }
  }

  useEffect(() => {
    fetchData();
  }, [authToken]);

  const handleAdminChange = (field: AdminSettingsAdminKeys, index: number, email: string) => {
    if (!settings) {
      return;
    }

    const updateAdmins = [
      ...settings[field].slice(0, index),
      {email},
      ...settings[field].slice(index + 1)
    ];

    const newSettings = {
      ...settings,
      [field]: updateAdmins
    };

    setSettings(newSettings);
  }

  const handleProductChange = (product: string, mapSize: string | null, value: number, field: string) => {
    if (!settings) {
      return;
    }

    const updatedPrices = settings?.price.map(p => {
      if (p?.product !== product  || p?.mapSize !== mapSize) {
        return p;
      }

      return {
        ...p,
        [field]: value
      }
    })

    const newSettings = {
      ...settings,
      price: updatedPrices
    };

    setSettings(newSettings);
  }

  const handleSave = async () => {
    if (!settings || !authToken) {
      return;
    }

    const updatedInvoiceAdmins = [...settings.invoiceAdmins, {email: invoiceAdminEmail.trim()}];
    const updatedAdmins = [...settings.admins, {email: adminEmail.trim()}];
    const updatedMapAdmins = [...settings.mapAdmins, {email: mapAdminEmail.trim()}];

    const updateSettings: AdminSettings = {
      ...settings,
      invoiceAdmins: updatedInvoiceAdmins.filter(a => a.email.trim() !== ''),
      admins: updatedAdmins.filter(a => a.email.trim() !== ''),
      mapAdmins: updatedMapAdmins.filter(a => a.email.trim() !== ''),
    }
    const updateSettingsRet = await updateAdminSettings(updateSettings, authToken);

    if (updateSettingsRet === null) {
      navigate("/", {state: { logout: true}});
    } else {
      setInvoiceAdminEmail('');
      setAdminEmail('');
      setMapAdminEmail('');

      await fetchData();
    }
  }

  const handleDownloadAllEmails = async () => {
    if (!authToken) {
      return;
    }
    const today = new Date();
    await downloadFile("/api/admin/load-all-emails", "JaktkartaEmails_All_" + today.toISOString().substring(0, 10) + ".xls", authToken);      
  }

  const handleDownloadNewEmails = async () => {
    if (!authToken) {
      return;
    }
    const today = new Date();    
    await downloadFile("/api/admin/load-new-emails", "JaktkartaEmails_New_" + today.toISOString().substring(0, 10) + ".xls", authToken);      
  }
  
  const handleFindSentEmails = async () => {
    if (sentEmail) {
     
        if (!authToken) {
          return;
        }
    
        const ret = await findSentEmail(sentEmail, authToken);
    
        if (ret === null) {
            navigate("/", {state: {logout: true}});
        } else {
            setSentEmail('');
            if (ret) {
                setSentEmails(ret);                
            } else {
                setSentEmails([]);
            }
        }
    }

    return false;
  }

  return (
    <div className={classes.adminPage}>
      <div className={classes.emails}>
        <div className={classes.emailsColumn}>
          <div className={classes.title}>Receivers of invoice document email</div>
          {
            (settings?.invoiceAdmins || []).map((a, index) => (
              <input
                type="email"
                value={a.email}
                onChange={(e) => handleAdminChange('invoiceAdmins', index, e.target.value)}
              />
            ))
          }

          <input
            type="email"
            value={invoiceAdminEmail}
            onChange={(e) => setInvoiceAdminEmail(e.target.value)}
          />
        </div>
        <div className={classes.emailsColumn}>
          <div className={classes.title}>Users</div>
          {
            (settings?.admins || []).map((a, index) => (
              <div key={index} className={classes.formRow}>
                <label htmlFor={`admin${index}`}>Admin</label>
                <input
                  id={`admin${index}`}
                  type="email"
                  className={classes.input}
                  value={a.email}
                  onChange={(e) => handleAdminChange('admins', index, e.target.value)}
                />
              </div>
            ))
          }
          <div className={classes.formRow}>
            <label htmlFor="admin">Admin</label>
            <input
              id="admin"
              type="email"
              className={classes.input}
              value={adminEmail}
              onChange={(e) => setAdminEmail(e.target.value)}
            />
          </div>
          {
            (settings?.mapAdmins || []).map((a, index) => (
              <div key={index} className={classes.formRow}>
                <label htmlFor={`mapAdmin${index}`}>Print house</label>
                <input
                  id={`mapAdmin${index}`}
                  type="email"
                  className={classes.input}
                  value={a.email}
                  onChange={(e) => handleAdminChange('mapAdmins', index, e.target.value)}
                />
              </div>
            ))
          }

          <div className={classes.formRow}>
            <label htmlFor="mapAdmin">Print house</label>
            <input
              id="mapAdmin"
              type="email"
              className={classes.input}
              value={mapAdminEmail}
              onChange={(e) => setMapAdminEmail(e.target.value)}
            />
          </div>
        </div>
      </div>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Product</th>
            {
              MAP_SIZES.map(m => <th>{m}</th>)
            }
          </tr>
        </thead>
        <tbody>
          {
            products.map(p => {
              const productPrices = settings?.price.filter(pr => pr && pr.product === p);

              if (!productPrices?.length) {
                return undefined;
              }

              return (
                <tr key={p} className={classes.row}>
                  <td className={classes.productName}>{productPrices[0].productName}</td>
                  {
                    MAP_SIZES.map(m => {
                      const checkMapSize = m === 'Annat' ? null : m;
                      const mapProduct = productPrices.find(pp => pp.mapSize === checkMapSize);

                      if (!mapProduct) {
                        return <td></td>
                      }


                      return (
                        <td className={classes.productPriceCol}>
                         <input
                           min={0}
                           type="number"
                           className={classes.productPriceInput}
                           value={mapProduct?.cost ?? 0 / 100}
                           onChange={(e) => handleProductChange(p, checkMapSize, e.target.valueAsNumber, 'cost')}
                         />

                          <input
                            min={0}
                            type="number"
                            className={classes.productPriceInput}
                            value={mapProduct?.printCost ?? 0 / 100}
                            onChange={(e) => handleProductChange(p, checkMapSize, e.target.valueAsNumber, 'printCost')}
                          />
                       </td>
                      )})
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <div className={classes.buttonContainer}>
        <div className={classes.priceDescriptionContainer}>
          Above: Customers' price incl. VAT <br/>
          Below: Supplier's cost excl. VAT      
        </div>
        <button className={classes.saveButton} onClick={handleSave}>Save</button>
      </div>
      <div className={classes.buttonEmailContainer}>
        <button className={classes.downloadEmailsButton} onClick={handleDownloadAllEmails}>Download all emails</button>
        <button className={classes.downloadEmailsButton} onClick={handleDownloadNewEmails}>Download new emails</button>
      </div>
      <div className={classes.sentEmailsContainer}>
        <div className={classes.title}>Find sent emails:</div>
        <input id="findSentEmail" className={classes.findInput} value={sentEmail} onChange={(e) => setSentEmail(e.target.value)}/>
        <Button className={classes.findButton} label="Find" onClick={handleFindSentEmails}/>
        {sentEmails && sentEmails.length > 0 && (
            <div>
                <SentEmailTable emails={sentEmails}/>
            </div>
        )}        
        
      </div>      
    </div>
  );
}

export default AdminPage;
