import {useEffect, useState} from "react";
import {getPageContent} from "../utils/api";

export const useContent = (page: string): any => {
  const [content, setContent] = useState<any>({})

  const fetchContent = async () => {
    const serverPages = await getPageContent(page);
    const pagesMap = (serverPages || []).reduce((p: any, curr: any) => ({
      ...p,
      [curr.code]: curr.value
    }), {});

    setContent(pagesMap);
  }

  useEffect(() => {

    fetchContent();
  }, []);

  return [content, setContent, fetchContent];
}