import React, {FC} from 'react';
import cn from "classnames";

import classes from './Information.module.scss';
import {HOME_INFORMATION, HOME_PRODUCTS} from "../../utils/pages";

type InformationProps = {
  content?: any;
  className?: string;
};

const Information: FC<InformationProps> = ({content, className}) => {
  return (
    <>
      {
        content && content[HOME_INFORMATION] ? (
          <div className={cn(className, classes.information)} dangerouslySetInnerHTML={{__html: content[HOME_INFORMATION] || ''}}></div>
        ) : (
          <div className={cn(className, classes.information)}>
            <div>Bygg kartan i WeHunt, Tracker, Easyhunt eller annat program. Ladda ner som PDF
              och beställ här.
            </div>
            <ul>
              <li>Storlekar från A4 till A0</li>
              <li>Tål vatten, smuts och sol</li>
              <li>PVC-duk: Skrynkel- och prasselfri</li>
              <li>Whiteboard: Skriv och sudda</li>
              <li>Magnetduk: Fäst på bilen</li>
            </ul>
          </div>
        )
      }
    </>
  );
}

export default Information;
