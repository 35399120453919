import React, {FC, FormEvent, useState} from 'react';

import classes from './PrintOrder.module.scss';
import Button from "../Button/Button";
import {Order} from "../../utils/Order";
import {OrderItem} from "../../utils/OrderItem";
import {ReactComponent as FilePdfSolidSvg} from 'images/file-pdf-solid.svg';
import {ReactComponent as CheckSvg} from 'images/check.svg';
import {ReactComponent as DeleteSvg} from 'images/delete.svg';
import {downloadFile} from "../../utils/api";
import {useAuth} from "../../utils/Auth";
import {useNavigate} from "react-router-dom";

type PrintOrderProps = {
  order: Order;
  checked?: boolean;
  buttonLabel?: string;
  onAction?: () => void;
  onToggle?: () => void;
  onAddComment?: (comment: string) => void;
  onDeleteComment?: (commentId: number) => void;
  onEditOrder?: () => void;  
}
const PrintOrder: FC<PrintOrderProps> = ({order, checked, buttonLabel, onAction, onToggle, onAddComment, onDeleteComment, onEditOrder}) => {
  const [comment, setComment] = useState('');
  const [downloading, setDownloading] = useState<Array<number>>([]);
  const {authToken} = useAuth();
  const navigate = useNavigate();

  const handleDownloadFile = async (e: React.MouseEvent<SVGSVGElement>, orderItem: OrderItem) => {
    e.stopPropagation();
    if (!authToken) {
      return;
    }

    setDownloading([...downloading, orderItem.id]);
    const ret = await downloadFile(orderItem.downloadPath, orderItem.fileName, authToken);

    setDownloading(downloading.filter(d => d !== orderItem.id));

    if (ret === null) {
      navigate("/", {state: { logout: true}});
    }
  }

  const handleAddComment = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (comment) {
      onAddComment && onAddComment(comment);
      setComment('');
    }

    return false;
  }

  return (
    <div className={classes.printOrder} onClick={onToggle}>
      <div className={classes.header}>
        {
          checked !== undefined && (
            <div className={classes.check}>
              {
                checked && <CheckSvg />
              }
            </div>
          )
        }
        <div className={classes.headerItem}>{order.orderNumber}</div>
        <div>{order.orderDate}</div>        
      </div>
      {
        order.shippingAddress && (
          <>
            <div>{order.shippingAddress.firstName} {order.shippingAddress.lastName}</div>
            <div>{order.shippingAddress.address}</div>
            <div>{order.shippingAddress.zip} {order.shippingAddress.city}</div>
            <div>{order.shippingAddress.country.name}</div>
            <div>{order.shippingAddress.email}</div>
            <div>{order.shippingAddress.mobile}</div>
          </>
        )
      }
      <hr/>
      <div className={classes.files}>
        {
          order.items.map((oi: OrderItem) => (
            <>
              <div>{oi.mapSize}</div>
              <div>{oi.productName}</div>
              <div>{oi.quantity}</div>
              <div>
                {
                  oi.downloadPath && (
                    downloading.includes(oi.id) ? (
                      <span className={classes.loader}></span>
                    ) : (
                      <FilePdfSolidSvg className={classes.pdfSvg} onClick={(e) => handleDownloadFile(e, oi)}/>
                    )
                  )
                }
              </div>
            </>
          ))
        }
      </div>
      <div className={classes.comments}>
        {
          (order.comments || []).map(c => (
            <div className={classes.comment}>
              <div>{c.description}</div>
              <DeleteSvg className={classes.commentDelete} onClick={() => onDeleteComment && onDeleteComment(c.id)}/>
            </div>
          ))
        }
        <form className={classes.commentActions} onSubmit={handleAddComment}>
          <input className={classes.commentInput} value={comment} onChange={(e) => setComment(e.target.value)}/>
          <Button
            className={classes.addCommentButton}
            label="Comment"
            onClick={handleAddComment}
          />
        </form>

      </div>
      {buttonLabel && (
        <Button
          className={classes.button}
          label={buttonLabel}
          onClick={onAction}
        />
      )
      }
      {            
      onEditOrder !== undefined && (
        <Button
          className={classes.editButton}
          label='Edit order'
          onClick={onEditOrder}
        />
      )
      }
      
    </div>
  );
}

export default PrintOrder;
