import React, {FC} from 'react';

import classes from './AddressField.module.scss';

type AddressFieldProps = {
  label: string;
  value: string;
}
const AddressField: FC<AddressFieldProps> = ({label, value}) => {
  return (
    <div className={classes.addressField}>
      <div className={classes.label}>
        {label}
      </div>
      <input value={value || ''} disabled={true} className={classes.input}/>
    </div>
  );
}

export default AddressField;
